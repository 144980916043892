<template>
  <div class="container-xxl position-relative bg-white d-flex p-0">
</div>
   <router-view/>
</template>

<script>

export default {
name:'AuthLayout',
components:{
    
}
}
</script>

<style>

</style>